import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, RatesTable, Link, AffiliateDisclosure, PlatformRatesFAQ } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Button, Pane } from 'evergreen-ui';
import { symbolToCurrencyMap, platformMap } from '../utils/constants';
import { slugToRatesMap } from '../data/ratesTableInfo';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/

const Page = ({ data, location }) => {
    const { pathname } = location;
    const splitPathname = pathname.split('/');
    const ratesSubdirectory = `/${splitPathname[1]}/`;
    let rates = slugToRatesMap[ratesSubdirectory];
    const aprOrApy = rates.APR ? 'APR' : 'APY';
    let title = data.ghostPage.title;
    let excerpt = `Current ${rates.platform} interest rates vs previous. Side by side comparison of the new and old rates to earn interest on your crypto. See the recent ${aprOrApy} changes of each cryptocurrency and stablecoin.`;
    let description = `Current ${rates.platform} interest rates vs previous. Side by side comparison of new & old rates. See recent changes to the ${aprOrApy} of each cryptocurrency.`;

    let cryptoSubset = 'all';
    if (splitPathname[2]) {
        const { oldUS, oldInt, newUS, newInt, platform } = rates;
        if (splitPathname[2] === 'stablecoins') {
            cryptoSubset = 'stablecoins';
            title = `${platform} Stablecoin Interest Rates: Current vs Previous`;
            excerpt = `Current ${platform} stablecoin interest rates vs previous. Side by side comparison of the new and old rates to earn on stablecoins / digital currencies pegged to "stable" assets. See the recent stablecoin ${aprOrApy} changes.`;
            description = `Current ${platform} stablecoin interest rates vs previous. Side by side comparison of new & old rates. See recent changes to the ${aprOrApy} of each stablecoin.`;
        } else {
            cryptoSubset = 'cryptocurrencies';
            title = `${platform} Crypto Interest Rates: Current vs Previous`;
            excerpt = `Current ${platform} crypto interest rates vs previous (non-stablecoins). Side by side comparison of the new and old rates to earn on your cryptocurrencies. See the recent ${aprOrApy} changes of each cryptocurrency.`;
            description = `Current ${platform} cryptocurrency interest rates vs previous. Side by side comparison of new & old crypto rates (non-stablecoins). See the recent ${aprOrApy} changes.`;
        }
        const tempArray = [
            { key: 'oldUS', currentRates: oldUS },
            { key: 'oldInt', currentRates: oldInt },
            { key: 'newUS', currentRates: newUS },
            { key: 'newInt', currentRates: newInt }
        ];
        tempArray.forEach(({ key, currentRates }) => {
            const filtered = { [key]: {} };

            for (let symbol in currentRates) {
                if (symbolToCurrencyMap[symbol].isStablecoin && (cryptoSubset === 'stablecoins')) {
                    filtered[key][symbol] = rates[key][symbol];
                } else if (!symbolToCurrencyMap[symbol].isStablecoin && (cryptoSubset === 'cryptocurrencies')) {
                    filtered[key][symbol] = rates[key][symbol];
                }
            }

            rates = { ...rates, [key]: filtered[key]}
        });
    }

    const page = { ...data.ghostPage, meta_title: title, title, excerpt, description };
    const newData = { ghostPage: page };

    return (
        <>
            <MetaData
                data={newData}
                location={location}
                type="website"
            />
            <Helmet>
                <style type="text/css">{`${page.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        { page.feature_image ?
                            <figure className="post-feature-image">
                                <img width="2" height="1" src={ page.feature_image } alt={`Logo - ${title}`} />
                            </figure> : null }
                        <h1 className="content-title">{title}</h1>
                        {/* The main page content */}
                        {/* <section
                            className="content-body load-external-scripts post-card-excerpt"
                            dangerouslySetInnerHTML={{ __html: page.html }}
                        /> */}
                        <section className="content-body load-external-scripts post-card-excerpt">{excerpt}</section>
                        <RatesTable aprOrApy={aprOrApy} rates={rates} ratesSubdirectory={ratesSubdirectory} pathname={pathname} />
                        <PlatformRatesFAQ aprOrApy={aprOrApy} platform={rates.platform} cryptoSubset={cryptoSubset} />
                        <Pane className="post-full-content" textAlign="center">
                            {/* <Button marginRight={12} size="large" appearance="primary" onClick={() => navigate('/blockfi-referral')}> */}
                            <hr/>
                            <Link to={platformMap[rates.key].versusPath} style={{ textDecoration: 'none' }}>
                                <Button marginRight={12} marginBottom={12} size="large" appearance="primary">
                                    Compare {rates.platform} vs Alternatives
                                </Button>
                            </Link>
                            <Link to="/crypto-interest-account-rates/" style={{ textDecoration: 'none' }}>
                                <Button marginRight={12} marginBottom={12} size="large">
                                    Other Platform Rates & Changes
                                </Button>
                            </Link>
                            <hr/>
                        </Pane>
                        {rates.bonusHTML && <section className="post-full-content  content-body">
                            <h2 id="sign-up-bonuses">{rates.platform} Sign Up Bonus</h2>
                        </section>}
                        <section
                            className="post-full-content content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: rates.bonusHTML }}
                        />
                        <AffiliateDisclosure />
                    </article>
                </div>
            </Layout>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
